<template>
	<ObiText class="obi-answers">
		<ObiText
			v-for="(item, index) in items"
			:key="index"
			class="obi-answer-card"
			:class="{
				'obi-answer-card--selected': selected && selected.id === item.id,
			}"
			@click="$emit('change', item)"
		>
			<emoji
				v-if="item.emoji"
				:emoji="{ id: item.emoji, skin: 3 }"
				:size="64"
				:backgroundImageFn="getBackgroundFn"
			/>
			<ObiText class="obi-answer-card-label" v-html="item.label[language]" />
		</ObiText>
	</ObiText>
</template>

<script>
import { Emoji } from "emoji-mart-vue";

export default {
	name: "ObiInput",
	components: {
		Emoji,
	},
	props: {
		value: {},
		size: {
			type: Number,
		},
		selected: {
			type: Object,
			default: null,
		},
		type: {
			type: String,
			default: "text",
		},
		items: {
			type: Array,
		},
		language: {
			type: String,
		},
	},
	methods: {
		getBackgroundFn() {
			return require("../../assets/images/icons/emoji-sheets.png");
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";

.obi-answers {
	display: flex;
	margin-top: 3rem;
	flex-flow: wrap;
	justify-content: center;
	align-items: center;
	gap: 20px;

	.obi-answer-card {
		cursor: pointer;
		background-color: transparent !important;
		border: 1px solid #ffffff;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;
		padding: 10px;
		gap: 10px;
		border-radius: 5px;

		&-label {
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			text-align: center;
			color: #ffffff;
		}

		&--selected {
			background: #1b3f86 !important;
		}
	}
}
</style>
